import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const imageBarSlider = document.querySelectorAll(
  ".js-ImageBar-keen-slider-container"
);

// Check if any ImageBars appear on page.
if (imageBarSlider) {
  // For each of them, generate a keen slider.
  imageBarSlider.forEach((imageBarSlider) => {
    var interval = 0;
    function autoplay(run) {
      clearInterval(interval);
      interval = setInterval(() => {
        if (run && slider) {
          slider.next();
        }
      }, 2000);
    }

    var slider = new KeenSlider(imageBarSlider, {
      loop: true,
      duration: 1000,
      slidesPerView: 4,
      dragStart: () => {
        autoplay(false);
      },
      dragEnd: () => {
        autoplay(true);
      },
    });
    autoplay(true);
  });
}
