const accordion = document.querySelector(".Accordion");
if (accordion) {
  const items = accordion.querySelectorAll(".accordion__item");
  const square = document.querySelectorAll(".accordion__square");

  items.forEach((item, key) => {
    const title = item.querySelector(".accordion__title");

    title.addEventListener("click", () => {
      const opened_item = accordion.querySelector(".is-open");
      // Toggle current item
      toggle_item(item);
      // Close earlier opened item if exists
      if (opened_item && opened_item !== item) {
        toggle_item(opened_item);
      }
      if (key === items.length - 1) {
        item.appendChild(square);
      }
    });
  });

  const toggle_item = (item) => {
    const body = item.querySelector(".accordion__body");
    if (item.classList.contains("is-open")) {
      body.removeAttribute("style");
      item.classList.remove("is-open");
    } else {
      body.style.height = body.scrollHeight + "px";
      item.classList.add("is-open");
    }
  };

  window.onload = () => {
    const body = document.querySelector(".accordion__body");
    const item = accordion.querySelector(".accordion__title");
    if (!item.classList.contains("is-open")) {
      body.style.height = body.scrollHeight + "px";
    }
  };
}
