const slides = document.querySelectorAll(".slide");
const left = document.querySelector("#arrow-left");
const right = document.querySelector("#arrow-right");
let index = 0;

if (slides && slides.length > 1) {
  function autoplay(run) {
    setInterval(function () {
      run && nextSlide(1);
    }, 7000);
  }

  autoplay(true);

  function prevSlide(n) {
    index += n;
    changeSlide();
  }

  function nextSlide(n) {
    index += n;
    changeSlide();
  }

  changeSlide();

  function changeSlide() {
    if (index > slides.length - 1) index = 0;

    if (index < 0) index = slides.length - 1;

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slides[index].style.display = "block";
  }

  right.addEventListener("click", () => nextSlide(1));
  left.addEventListener("click", () => nextSlide(-1));
}
